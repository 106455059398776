import React, { useContext, useEffect, useState } from 'react'
import SelectCityModal from '../modals/SelectCityModal';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import AppServices from '../../services/general/appServices';

const SelectCity = observer(() => {
    const {app} = useContext(Context);
    const [openModal, setOpenModal] = useState();

    const fetchCities = async () => {
        const res = await AppServices.locations();

        if(res.statusCode === 200){
            const newArr = res.content.items.map(it =>{
                let temp = {
                    value: it.id,
                    label: it.title,
                    location: it.value
                }
                return temp
            })
            app.setAllCity(newArr)
        }
    }

    useEffect(() => {
        fetchCities();
    }, [])

    useEffect(() => {
        if(localStorage.getItem('city') && localStorage.getItem('city') != 'undefined'){
            let temp = JSON.parse(localStorage.getItem('city'));
            if((app.allCities?.find(it => it.value == temp.value && it.label == temp.label))){
                app.setCity(JSON.parse(localStorage.getItem('city')));
            }else{
                app.allCities?.length >= 1 && app.setCity(app.allCities[0]);
                setOpenModal(true);
            }
        }else{
            app.allCities?.length >= 1 && app.setCity(app.allCities[0]);
            setOpenModal(true)
        }
    }, [app.allCities])

  return (
    app.allCities?.length >= 1 &&
    <>
        <div className='select-city' onClick={() => setOpenModal(true)}>
            <i className='fa fa-map-marker'></i>
            {JSON.parse(localStorage.getItem('city'))?.label}
        </div>
        {openModal && <SelectCityModal show={openModal} setShow={setOpenModal}/>}
    </>
  )
})

export default SelectCity