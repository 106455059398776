import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { useEffect } from 'react';
import ProductContainer from '../../components/complex/ProductContainer';
import AboutUs from '../../components/complex/AboutUs';
import Advantages from '../../components/complex/Advantages';
import OurTeam from '../../components/complex/OurTeam';
import ReviewSection from '../../components/complex/ReviewSection';
import FaqComponent from '../../components/complex/FaqComponent';
import OurBusiness from '../../components/complex/OurBusiness';
import Excellences from '../../components/complex/Excellences';
import OurBlog from '../../components/complex/OurBlog';
import BannerSection from '../../components/simple/BannerSection';
import BannerSection2 from '../../components/simple/BannerSection2';
import Newsletter from '../../components/complex/Newsletter';
import DayOffer from '../../components/complex/DayOffer';
import ProductsGrid from '../../components/complex/ProductsGrid';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CatalogTop from '../../components/simple/CatalogTop';

const Catalog = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {slug} = useParams();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/catalog/${slug}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'catalog')

  return (
    <>
        {loading ?
            <div className="fullpage-loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            :
            <div>
                <CatalogTop item={items}/>
                <ProductContainer item={items} slug={slug}/>
                {items?.elements?.map((elem, index) =>
                    <div key={index}>
                        {elem.type === "about" ?
                            <AboutUs elem={elem}/>
                            :
                        elem.type === "advantages" ?
                            <Advantages item={elem}/>
                            :
                        elem.type === "workers" ?
                            <OurTeam elem={elem}/>
                            :
                        elem.type === "reviews" ?
                            <ReviewSection elem={elem}/>
                            :
                        elem.type === "faq" ?
                            <FaqComponent elem={elem}/>
                            :
                        elem.type === "advantages-2" ?
                            <OurBusiness elem={elem}/>
                            :
                        elem.type === "steps" ?
                            <Excellences elem={elem}/>
                            :
                        elem.type === "articles" ?
                            <OurBlog elem={elem}/>
                            :
                        elem.type === "advertising-banner-2" ?
                            Number(elem.banner_type) === 1 ?
                                <BannerSection item={elem}/>
                                :
                            Number(elem.banner_type) === 2 ?
                                <BannerSection2 item={elem}/>
                                :
                            Number(elem.banner_type) === 3 ?
                                <Newsletter item={elem}/>
                                :
                                <></>
                            :
                        elem.type === "products" ?
                            elem.products_type === "recommended" ?
                                <DayOffer item={elem}/>
                                :
                                <ProductsGrid item={elem}/>
                            :
                            <></>
                        }
                    </div>
                )}
            </div>
        }
    </>
  )
})

export default Catalog